import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import content from '../content/about'

const Wrapper = styled.div`
  padding: 3rem;
  max-width: 800px;

  > * {
    margin: 2rem 0;
  }

  h4 {
    margin-bottom: 2rem;
  }
`

const Bio = styled.p`
  font-size: 1.25rem;
  line-height: 1.3;
`

const ListItem = styled.li`
  display: flex;
  margin-top: 1rem;

  > div {
    margin-left: 1rem;
  }

  p {
    line-height: 1.1;
  }
`

const ListDisc = styled.ul`
  list-style: disc;
  padding-inline-start: 2rem;

  p {
    line-height: 1.1;
  }

  li {
    margin-top: 1rem;
  }
`

const Year = styled.p``

const About = () => (
  <Layout>
    <SEO title="About" />
    <Wrapper>
      <Bio>{content.bio}</Bio>
      <div>
        <h4>Solo Exhibitions</h4>
        <ul>
          {content.exhibitions.solo.map((show) => (
            <ListItem>
              <Year>{show.year}</Year>
              <div>
                <p>{show.gallery}</p>
                <p>{show.title && `Show title: ${show.title}`}</p>
                <p>{show.locale}</p>
              </div>
            </ListItem>
          ))}
        </ul>
      </div>
      <div>
        <h4>Group Exhibitions</h4>
        <ul>
          {content.exhibitions.group.map((show) => (
            <ListItem>
              <Year>{show.year}</Year>
              <div>
                <p>{show.gallery}</p>
                <p>{show.title && `Show title: ${show.title}`}</p>
                <p>{show.locale}</p>
              </div>
            </ListItem>
          ))}
        </ul>
      </div>
      <div>
        <h4>Awards/Scholarships</h4>
        <ul>
          {content.awards.map((award) => (
            <ListItem>
              <Year>{award.year}</Year>
              <div>
                <p>{award.gallery}</p>
                <p>{award.title}</p>
                <p>{award.organization}</p>
                <p>{award.locale}</p>
              </div>
            </ListItem>
          ))}
        </ul>
      </div>
      <div>
        <h4>Presentations/Conferences</h4>
        <ListDisc>
          {content.presentations.map((presentation) => (
            <li>
              <div>
                <p>{presentation.date}</p>
                <p>{presentation.sponsor}</p>
                <p>{presentation.locale}</p>
              </div>
            </li>
          ))}
        </ListDisc>
      </div>
      <div>
        <h4>Publications</h4>
        <ListDisc>
          {content.publishedFeatures.map((feature) => (
            <li>
              <div>
                <p>{feature.publication}</p>
                <p>{feature.title}</p>
                <p>{feature.issue}</p>
                <p>{feature.country}</p>
              </div>
            </li>
          ))}
        </ListDisc>
      </div>
    </Wrapper>
  </Layout>
)

export default About
