export default {
  bio:
    'Shiho is an abstract painter from Japan and currently lives in USA. Her work was introduced in the American Artist magazine and the American Artist Watercolor magazine. She has had solo exhibitions and group exhibitions in New York and Tokyo.',
  exhibitions: {
    solo: [
      {
        year: '2015',
        gallery: 'Noho and M55 Art Gallery ',
        title: 'Abstract Life',
        locale: 'New York, NY'
      },
      {
        year: '2011',
        gallery: 'Park Avenue Bistro',
        title: null,
        locale: 'New York, NY'
      },
      {
        year: '2009',
        gallery: 'Pillow café',
        title: null,
        locale: 'Brooklyn, NY'
      },
      {
        year: '2009',
        gallery: 'Hayato New York',
        title: null,
        locale: 'New York, NY'
      },
      {
        year: '2008',
        gallery: 'Bar Rase',
        title: null,
        locale: 'Brooklyn, NY'
      }
    ],
    group: [
      {
        year: '2015',
        gallery: 'Noho and M55 Art Gallery',
        title: 'Invitational',
        locale: 'New York, NY'
      },
      {
        year: '2014',
        gallery: 'Noho and M55 Art Gallery',
        title: 'Mixing Media',
        locale: 'New York, NY'
      },
      {
        year: '2013',
        gallery: 'Noho and M55 Art Gallery',
        title: "Who's Who",
        locale: 'New York, NY'
      },
      {
        year: '2013',
        gallery: 'Isetan Art Gallery',
        title: "Children's Room",
        locale: 'Tokyo, Japan'
      },
      {
        year: '2012',
        gallery: 'Daikanyama Hillside Terrace, Gallery A',
        title: 'Top 5 Artists',
        locale: 'Tokyo, Japan'
      },
      {
        year: '2012',
        gallery: 'Daikanyama Hillside Terrace, Hillside Forum F',
        title: 'CHIPS2012',
        locale: 'Tokyo, Japan'
      },
      {
        year: '2011',
        gallery: 'Phyllis Harriman Gallery, Art Student League',
        title: null,
        locale: 'New York, NY'
      },
      {
        year: '2010',
        gallery: 'NY Coo Gallery',
        title: null,
        locale: 'New York, NY'
      },
      {
        year: '2010',
        gallery: 'Space Womb Gallery',
        title: null,
        locale: 'Long Island City, NY'
      },
      {
        year: '2010',
        gallery: 'Ise Cultural Foundation Gallery',
        title: null,
        locale: 'New York, NY'
      },
      {
        year: '2010',
        gallery: 'Phyllis Harriman Gallery, Art Student League',
        title: null,
        locale: 'New York, NY'
      }
    ]
  },
  awards: [
    {
      year: 2010,
      title: 'Martha T. Rosen Memorial Scholarship',
      organization: 'The Art Students League of New York'
    },
    {
      year: 2010,
      title: 'Ali Hossani Award',
      organization: 'Ise Cultural Foundation Gallery',
      locale: 'New York, NY'
    }
  ],
  presentations: [
    {
      date: 'March 26, 2013',
      sponsor: 'Tanabe Hamayu Rotary Club',
      locale: 'Wakayama, Japan'
    },
    {
      date: 'January 16, 2013',
      sponsor: 'International Student Research Forum, Wakayama University',
      locale: 'Wakayama, Japan'
    }
  ],
  publishedFeatures: [
    {
      title: 'New Artists',
      publication: 'Contemporary Art 2, Art Box',
      issue: 2012,
      country: 'Japan'
    },
    {
      title: '25th Anniversary, 25 Artists to Watch',
      publication: 'Contemporary Art 2, Art Box',
      issue: 'Fall 2011, page 73',
      country: 'USA'
    },
    {
      title: 'Introduced Blick and Faber Castell',
      publication: 'American Artist Magazine',
      issue: 'June 2011, pages 30-31',
      country: 'USA'
    }
  ]
}
